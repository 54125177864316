exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bond-js": () => import("./../../../src/pages/bond.js" /* webpackChunkName: "component---src-pages-bond-js" */),
  "component---src-pages-funko-js": () => import("./../../../src/pages/funko.js" /* webpackChunkName: "component---src-pages-funko-js" */),
  "component---src-pages-holocloud-js": () => import("./../../../src/pages/holocloud.js" /* webpackChunkName: "component---src-pages-holocloud-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newhero-js": () => import("./../../../src/pages/newhero.js" /* webpackChunkName: "component---src-pages-newhero-js" */),
  "component---src-pages-obo-js": () => import("./../../../src/pages/obo.js" /* webpackChunkName: "component---src-pages-obo-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-playground-js": () => import("./../../../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */),
  "component---src-pages-sway-js": () => import("./../../../src/pages/sway.js" /* webpackChunkName: "component---src-pages-sway-js" */)
}

